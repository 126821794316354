import React from 'react';
import '../App.css';

import ButtonEnergy from './Button_Energy/ButtonEnergy';
import './HeroSection.css';
import logo from './logo.png'
import Backdrop from './backdrop.jpg'

function HeroSection() {
  return (
    <div className='hero-container'>
      <img className='backdrop' src={Backdrop}/>
      <div className='hero-container-2'>
      <img src={logo} alt='logo' className='logo_hero'/>
      <h1 className='Hero_Heading'>NOKWAZIMED</h1>
      <div className='Hero_Heading_2'>Your Medical and Surgical Supplier for the Healthcare Industry</div>
      
      <div className='hero-btns'>
        <ButtonEnergy
          url='/services'
          title='SERVICES'
          
        >
        </ButtonEnergy>

        <ButtonEnergy
          url='/ContactUs'
          title='CONTACT US'
          
        >
        </ButtonEnergy>
        </div>
      </div>
    </div>
  );
}

export default HeroSection;
