const DataForEvent = [
    {
        id: '1',
        title: 'About Us',
        desc: "Nokwazimed is a privately owned South African company which has recently been founded after South Africa achieved democracy. Nokwazimed Distributors' customer base is extensive, throughout Africa. Our main field of expertise lies in the supply of medical goods to institutions and patients as well as the supply of medicine to registered vendors only.",
        img: 'images/aboutus.jpg',
        url: 'Title 1',
    },

    {
        id: '2',
        title: 'Transforming Healthcare',
        desc: 'Nokwazimed has a national footprint in South Africa with the head office based in Johanesburg, Gauteng which has one of the biggest and busiest Airports in Africa and branches strategically placed in Johannesburg and Cape Town. The company has a large production warehouse including a modern Ethylene Oxide sterilization plant and administrative offices.',
        img: 'images/transforminghealth.jpg',
        url: 'Title 1',
    },
    {
        id: '3',
        title: 'Our Products',
        desc: 'Our services range from the supply of an adult nappy for a patient to providing medical equipment to large companies and hospitals that can range from a single item to total refurbishing of units and wards. We service customers throughout South Africa and Southern Africa by means of courier and own delivery.',
        img: 'images/prods2.jfif',
        url: 'Title 1',
    },
    
    
    ]
    
    export default DataForEvent