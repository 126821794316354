import React from 'react'
import './Contactus.css'

function ContactUs() {
    return (
        <div className='contactus_wrapper'>
            <div className='contactus_text_area'>
                <div className='heading_contactus'>
                    CONTACT US
                </div>
                <div className='phoneNumber_text'>
                    <div className='small_text'>
                        Contact Person
                    </div>
                    <div className='large_text'>
                    
                    Ethle Nthite
                    </div>
                </div>
                <div className='phoneNumber_text'>
                    <div className='small_text'>
                        Phone Number
                    </div>
                    <div className='large_text'>
                    082 318 3532
                    </div>
                </div>
                <div className='phoneNumber_text'>
                    <div className='small_text'>
                        Email Address
                    </div>
                    <div className='large_text'>
                    Nokwazimed@gmail.com
                    </div>
                </div>

                <div className='phoneNumber_text'>
                    <div className='small_text'>
                        Physical Address
                    </div>
                    <div className='large_text'>
                    11 Oscar road, Hughes, Boksburg, Gauteng
                    </div>
                </div>


            </div>
        </div>
    )
}

export default ContactUs