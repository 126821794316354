import React from 'react'
import SectorCard from './SectorCard'
import sectordata from './SectorCard_DATA.js'
import './SectorCards.css'
import ButtonEnergy from '../Button_Energy/ButtonEnergy'

function SectorMainDisplay() {

    const dataText = "With attention to service and customer relations, you can be assured that Nokwazimed is your supplier of choice. We have a dedicated team to attend to all your needs with emphasis on service and support. All our products carry international certifications, to ensure quality standards across all furniture, equipment and medical consumables. Our aim is satisfying all our customers' needs, so if we don't have it, we will source it for you!"

    return (
        <div className='parent_sectors_main'>
            <div className='sector_cards_display_2'>
                <div className='Hero_Heading_services'>SERVICES</div>
                <div className='subtitle_section_hero_services'>
                    <div className='Hero_Heading_2_services'>NOKWAZIMED IS A PRIVATELY-OWNED COMPANY OPERATING OUT OF JOHANNESBURG, SOUTH AFRICA.</div>
                    <div className='Hero_Heading_3_services'>ONE OF SOUTH AFRICA'S LEADING SUPPLIER OF CONSUMABLES TO THE MEDICAL INDUSTRY</div>
                </div>
                <div className='title_sector_main'>We supply a large range of top quality medical products across South Africa, within the following sectors</div>
            </div>
            <div className='sector_cards_display'>

                {sectordata.map((data) => { return (<SectorCard title={data.title} img={data.img} />) })}
            </div>
            <div className='text_wrapper_sections'>
                <div className='text_section_bottom'>
                    {dataText}
                    <ButtonEnergy
                        url='/ContactUs'
                        title='GET A QUOTE'
                    />
                    Nokwazimed Medical Supplies is a Level 4 BEE Contributor.
                </div>



            </div>
        </div>

    )
}

export default SectorMainDisplay